import { showErrorMessage } from "components/AppMessage/AppMessage";
import { setLockScreen } from "services/localStore/actions/appSettings";
import store from "services/localStore/store";

import Authenticator from "./authenticator";

/**
 * Sends an authenticated HTTP request with application/json content.
 * @param  {url} url The web address.
 * @param  {string} [method = "GET"] The HTTP method.
 * @param  {object} [body = null] The data to send in the body as json.
 * @param  {boolean} [parseErrors = true] Parse and show response errors.
 * @param  {boolean} [lockScreen = false] Lock screen while fetching.
 * @return Promise with the response.
 */
export const authFetchAsync = async (url, method = "GET", body = null, parseErrors = true, isScreenToLock = false) => {
    const token = await getUserTokenAsync();
    const state = store.getState();

    if (isScreenToLock) {
        store.dispatch(setLockScreen(true));
    }

    return new Promise((resolve) => {
        fetch(url, {
            method: method,
            headers: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + token,
                "FundManager-Language": state.userSettings.language ? state.userSettings.language : "",
            }),
            body: body != null ? JSON.stringify(body) : undefined,
        }).then(function (response) {
            if (response.status === 401 && !url.includes("authorization/users/me")) {
                window.location.href = "/relogin";
                return;
            }
            if (parseErrors && !response.ok) {
                parseResponseErrorAsync(response).then((msg) => {
                    showErrorMessage(msg);
                });
            }
            if (isScreenToLock) {
                store.dispatch(setLockScreen(false));
            }
            resolve(response);
        });
    });
};

/**
 * Sends a raw authenticated HTTP request.
 * @param  {url} url The web address.
 * @param  {string} [method = "GET"] The HTTP method.
 * @param  {object} [body = null] The data to send in the body.
 * @param  {boolean} [parseErrors = true] Parse and show response errors.
 * @param  {boolean} [lockScreen = false] Lock screen while fetching.
 * @return Promise with the response.
 */
export const authFetchRawAsync = async (
    url,
    method = "GET",
    body = null,
    parseErrors = true,
    isScreenToLock = false,
    isJSONRequest = false
) => {
    const token = await getUserTokenAsync();
    const state = store.getState();

    if (isScreenToLock) {
        store.dispatch(setLockScreen(true));
    }

    return new Promise((resolve) => {
        const headers = new Headers({
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + token,
            "FundManager-Language": state.userSettings.language ? state.userSettings.language : null,
        });
        if (isJSONRequest) {
            headers.append("Content-Type", "application/json");
        }

        fetch(url, {
            method: method,
            headers: headers,
            body: body !== null ? body : undefined,
        }).then(function (response) {
            if (parseErrors && !response.ok) {
                parseResponseErrorAsync(response).then((msg) => {
                    showErrorMessage(msg);
                });
            }

            if (isScreenToLock) {
                store.dispatch(setLockScreen(false));
            }

            resolve(response);
        });
    });
};

/**
 * Sends a raw authenticated HTTP request.
 * @param  {component} component The react component.
 * @param  {string} dataPropertyName = "data" The state property name holding the data to send/receive.
 * @param  {string} isLoadingPropertyName = "isLoading" The state property name storing a loading indicator.
 * @param  {url} url The web address.
 * @param  {string} [method = "GET"] The HTTP method.
 * @param  {boolean} [sendAsFormData = false] Indicates if the data should be sent as FormData (default is json).
 * @return Promise with the response.
 */
export const authFetchIntoStateAsync = async (
    component,
    dataPropertyName = "data",
    isLoadingPropertyName = "isLoading",
    url,
    method = "GET",
    sendAsFormData = false
) => {
    return new Promise(function (resolve) {
        component.setState(
            () => {
                return { [isLoadingPropertyName]: true };
            },
            async () => {
                let response;

                if (sendAsFormData) {
                    const formData = new FormData();

                    if (
                        typeof component.state[dataPropertyName] === "object" &&
                        !(component.state[dataPropertyName] instanceof File)
                    )
                        for (const key in component.state[dataPropertyName])
                            formData.append(key, component.state[dataPropertyName][key]);
                    else formData.append(dataPropertyName, component.state[dataPropertyName]);
                    response = await authFetchRawAsync(url, method, formData);
                } else {
                    response = await authFetchAsync(
                        url,
                        method,
                        method === "POST" ? component.state[dataPropertyName] : null
                    );
                }

                if (response.ok) {
                    const content = await response.text();
                    if (content) {
                        const data = JSON.parse(content);

                        // [Paginated lists only]
                        if (data && data.items && data.pageNumber) {
                            if (
                                component.state.data.items &&
                                component.state.data.items.length > 0 &&
                                data.pageNumber > 1
                            ) {
                                // console.log("Appending page", data.pageNumber);
                                data.items = component.state.data.items
                                    .slice(0, component.state.data.items.length - 1)
                                    .concat(data.items);
                            }
                            if (data.pageNumber < data.pageCount) {
                                // console.log("Appending continuation token");
                                data.items.push(null);
                            }
                        }

                        component.setState(
                            () => {
                                return { [isLoadingPropertyName]: false, [dataPropertyName]: data };
                            },
                            () => resolve(response)
                        );
                    } else {
                        component.setState(
                            () => {
                                return { [isLoadingPropertyName]: false };
                            },
                            () => resolve(response)
                        );
                    }
                    // Operation Succeeded
                    // if (method !== "GET") showSuccessMessage(i18next.t("msg.action.success"));
                } else {
                    component.setState(
                        () => {
                            return { [isLoadingPropertyName]: false };
                        },
                        () => resolve(response)
                    );
                    // reject(response);
                }
            }
        );
    });
};

/**
 * Download a file with an authenticated HTTP request.
 * @param  {url} url The web address.
 * @return Promise.
 */
export const authDownloadAsync = async (url, method = "GET", body = null) => {
    return new Promise((resolve, reject) => {
        authFetchRawAsync(url, method, body ? JSON.stringify(body) : null, true, false, true)
        .then(async (response) => {
            if (!response.ok) {
                reject(response);
                return;
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            const contentDisposition = response.headers.get("Content-Disposition");
            let fileName = "download";
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^;"]+)[";]/);
                if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
            }
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            resolve();
        })
        //.catch((error) => {console.error(error) });
    });
};

/**
 * Download a file with an authenticated HTTP request.
 * @param  {url} url The web address.
 * @return Promise.
 */
export const authDownloadParamAsync = async (url, method, request) => {
    return new Promise((resolve, reject) => {
        authFetchAsync(url, method, request).then(async (response) => {
            if (!response.ok) {
                reject(response);
                return;
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            const contentDisposition = response.headers.get("Content-Disposition");
            let fileName = "download";
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^;"]+)[";]/);
                if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
            }
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            resolve();
        });
        //.catch((error) => { });
    });
};

export async function getUserTokenAsync() {
    const user = await Authenticator.getUserAsync();
    return user != null ? user.access_token : null;
}

export function parseResponseErrorAsync(response) {
    return new Promise((resolve) => {
        response.text().then((content) => {
            console.error(`${response.statusText} ${content}`);

            const json = tryParseJSON(content);
            if (!json) {
                // Don't show HTML as an error message
                if (content.startsWith("<!DOCTYPE")) content = null;
                resolve(content || `${response.statusText}:\n ${response.url}`);
            }

            // json example: {"error": [ "msg1", "msg2"], "error2": [ "msg3" ] };
            let result = "";
            for (const key in json) {
                // skip loop if the property is from prototype
                // eslint-disable-next-line no-prototype-builtins
                if (!json.hasOwnProperty(key)) continue;

                const obj = json[key];
                if (obj.constructor !== Array) continue;

                for (const s of obj) {
                    result = s.errorMessage ? s.errorMessage : result ? `${result}\n${s}` : s;
                }
            }
            resolve(result);
        });
    });
}

function tryParseJSON(jsonString) {
    try {
        const o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    } catch (e) {
        console.error("authFetch tryParseJSON error", e);
    }

    return false;
}
